<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("selectFile") }}
        <div class="card-header-actions">
          <BrandButton
            buttonClass="mr-1 btn-brand bg-danger"
            :text="$t('back')"
            buttonIcon="fa fa-backward"
            @click="back()"
          ></BrandButton>

          <BrandButton
            buttonClass="mr-1 btn-brand bg-success"
            :text="$t('save')"
            v-if="
              selectedNews.agencyNewsFileList != null &&
              $auth.hasAuth($options, 'save')
            "
            buttonIcon="fa fa-save"
            @click="save()"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row v-if="videoList.length > 0">
          <b-col sm="3">
            <label for="dataTableVideo">
              <h3>
                {{ $t("videos") }}
              </h3>
            </label>
            <v-client-table
              :columns="columnsVideo"
              :data="videoList"
              :options="options"
              id="dataTableVideo"
              class="grid-height"
            >
              <template slot="thumbnail" slot-scope="props">
                <video
                  class="img-thumbnail img-fluid"
                  :src="props.row.link"
                  height="50"
                  width="65"
                  :id="getPopoverId(props.row.id)"
                />
                <b-popover
                  :target="getPopoverId(props.row.id)"
                  triggers="hover"
                  placement="right"
                >
                  <template #title>{{ $t("video") }}</template>
                  <video
                    class="img-thumbnail img-fluid"
                    :src="props.row.link"
                    height="400"
                    width="400"
                    :id="getPopoverId(props.row.id)"
                  />
                </b-popover>
              </template>

              <template :slot="columnsVideo[1]" slot-scope="props">
                <div class="text-center">
                  <CustomCheckBox
                    ref="videoGallery"
                    customClass="pt-2"
                    @input="(val) => selectVideoGallery(val, props.row.id)"
                  />
                </div>
              </template>
            </v-client-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <label for="dataTableVideo">
              <h3>
                {{ $t("images") }}
              </h3>
            </label>
            <v-client-table
              :columns="columns"
              :data="imagesList"
              :options="options"
              id="dataTable"
              class="dataGrid"
            >
              <template slot="thumbnail" slot-scope="props">
                <b-img
                  :src="props.row.link"
                  thumbnail
                  fluid
                  height="40"
                  width="40"
                  v-if="props.row.fileType.includes('image')"
                  :id="getPopoverId(props.row.id)"
                />

                <video
                  class="img-thumbnail img-fluid"
                  :src="props.row.link"
                  height="40"
                  width="40"
                  :id="getPopoverId(props.row.id)"
                  v-if="!props.row.fileType.includes('image')"
                />
                <b-popover
                  :target="getPopoverId(props.row.id)"
                  triggers="hover"
                  placement="right"
                >
                  <template #title>{{ $t("image") }}</template>
                  <b-img
                    :src="props.row.link"
                    thumbnail
                    fluid
                    height="400"
                    width="400"
                    v-if="props.row.fileType.includes('image')"
                  />
                  <video
                    class="img-thumbnail img-fluid"
                    :src="props.row.link"
                    height="400"
                    width="400"
                    :id="getPopoverId(props.row.id)"
                    v-if="props.row.fileType.includes('video')"
                  />
                </b-popover>
              </template>

              <template
                :slot="col"
                slot-scope="props"
                v-for="(col, index) in radioColumns"
              >
                <div v-bind:key="col" class="text-center">
                  <radio
                    v-if="
                      !isGalleryType(columns[index + 1]) &&
                      !isVideoGalleryType(columns[index + 1])
                    "
                    :key="columns[index + 1]"
                    :name="getRadioGroupName(columns[index + 1])"
                    :value="props.row.id"
                    :ref="generateRadioRef(props.row.id, columns[index + 1])"
                    @input="(val) => radioChanged(val, columns[index + 1])"
                  />
                  <CustomCheckBox
                    v-if="
                      isGalleryType(columns[index + 1]) &&
                      props.row.fileType.includes('image')
                    "
                    customClass="pt-2"
                    ref="imageGallery"
                    @input="(val) => selectGallery(val, props.row.id)"
                  />
                </div>
              </template>
            </v-client-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import http from "../../shared/http";
import gridOptions from "../../shared/grid-options";
import { ClientTable } from "vue-tables-2";
import BrandButton from "../../widgets/form/CustomBrandButton";
import CustomCheckBox from "../../widgets/form/CustomCheckBox";
import { Radio } from "vue-checkbox-radio";
import toastHelper from "../../shared/toast-helper";
export default {
  name: "NewsAgenciesFileType",
  props: {
    selectedNews: { type: Object, required: true },
    fileTypeEntities: { type: Array, required: true },
  },
  components: {
    ClientTable,
    Radio,
    BrandButton,
    CustomCheckBox,
  },
  data() {
    return {
      columns: ["thumbnail"],
      columnsVideo: ["thumbnail"],
      radioColumns: [],
      options: {},
      selectedData: [],
      selectedGallery: [],
      selectedVideoGallery: [],
      imagesList: this.selectedNews.agencyNewsFileList.filter((p) =>
        p.fileType.includes("image")
      ),
      videoList: this.selectedNews.agencyNewsFileList.filter((p) =>
        p.fileType.includes("video")
      ),
    };
  },
  created() {
    this.setColumns();
  },
  methods: {
    getRadioGroupName(column) {
      const fileTypeId = this.fileTypeEntities.filter(
        (p) => p.entityName == column
      );
      if (fileTypeId.length > 0) {
        const fileTypeIdObject = fileTypeId[0].id;
        const relatedPositionId = this.$appSetting.bindFileTypePosition[
          fileTypeIdObject
        ];
        if (relatedPositionId !== undefined) {
          const checkIsMainPosition = this.$appSetting.MainPositions.filter(
            (p) => p.id == relatedPositionId
          );
          if (checkIsMainPosition.length > 0) {
            return "mainGroup";
          } else return column;
        } else return column;
      } else return column;
    },
    getPopoverId(id) {
      return "popover" + id;
    },
    generateRadioRef(id, col) {
      return id + col;
    },
    // radioClicked(refId, columnIndex) {
    //   const column= this.columns[columnIndex]
    //   const lastValue = this.selectedData[column];
    //   const currentValue = refId;
    //   if (lastValue == currentValue) {
    //     //this.$refs[refId].checked = false;
    //     //this.$refs[refId].state = false;
    //     this.$refs[refId + column].key = column.toLowerCase();
    //     console.log(this.$refs[refId + column]);
    //   }
    // },
    resetAllMainGroupColumns() {
      this.columns.forEach((col) => {
        if (this.getRadioGroupName(col) == "mainGroup") {
          this.selectedData[col] = 0;
        }
      });
    },
    radioChanged(val, column) {
      if (this.getRadioGroupName(column) == "mainGroup") {
        this.resetAllMainGroupColumns();
      }
      this.selectedData[column] = val;
    },
    resetGallery(gallery) {
      if (gallery != null) {
        if (gallery == "image") {
          this.selectedGallery = null;
          this.selectedGallery = [];
        } else {
          this.selectedVideoGallery = null;
          this.selectedVideoGallery = [];
        }

        const type = gallery == "video" ? "videoGallery" : "imageGallery";
        // Trigger for just one video record
        if (this.videoList.length > 0) {
          if (
            gallery == "video" &&
            this.$refs[type] &&
            this.$refs[type].length === undefined
          ) {
            this.$refs[type].loadedValue = false;
          }
        }
        // Trigger for just one video record
        if (this.videoList.length == 0 && gallery == "video") {
          return;
        }
        if (this.$refs[type])
          for (let i = 0; i < this.$refs[type].length; i++) {
            this.$refs[type][i].loadedValue = false;
          }
      }
    },
    isVideoGalleryType(column) {
      const fileType = this.fileTypeEntities.filter(
        (p) => p.entityName == column
      );
      return fileType[0].id == 10;
    },
    isGalleryType(column) {
      const fileType = this.fileTypeEntities.filter(
        (p) => p.entityName == column
      );
      return fileType[0].id == 9;
    },
    selectGallery(state, fileId) {
      if (state) {
        this.resetGallery("video");
        this.selectedGallery.push(fileId);
      } else {
        const checkIndex = this.selectedGallery.indexOf(fileId);
        if (checkIndex != -1) this.selectedGallery.splice(checkIndex, 1);
      }
    },
    selectVideoGallery(state, fileId) {
      if (state) {
        this.resetGallery("image");
        this.selectedVideoGallery.push(fileId);
      } else {
        const checkIndex = this.selectedVideoGallery.indexOf(fileId);
        if (checkIndex != -1) this.selectedVideoGallery.splice(checkIndex, 1);
      }
    },
    back() {
      this.$emit("onGoBack");
    },
    save() {
      let model = [];
      let positionIds = [];
      let NormalNewsTypeId = 2;
      const positionList = this.$appSetting.bindFileTypePosition;
      for (let i = 1; i < this.columns.length; i++) {
        if (this.selectedData[this.columns[i]] != 0) {
          let fileId = this.selectedData[this.columns[i]];
          let entityId = this.fileTypeEntities.filter(
            (p) => p.entityName == this.columns[i]
          )[0].id;
          if (entityId != 10) {
            model.push({
              fileId: fileId,
              entityId: entityId,
            });
          }
          const positionId =
            positionList[
              this.fileTypeEntities.filter(
                (p) => p.entityName == this.columns[i]
              )[0].id
            ];
          if (positionId !== undefined) {
            positionIds.push(positionId[0]);
          }
        }
      }
      if (this.selectedGallery.length > 0) {
        NormalNewsTypeId = 4;
        this.selectedGallery.forEach((galleryFile) => {
          model.push({
            fileId: galleryFile,
            entityId: 9,
          });
        });
      }
      if (this.selectedVideoGallery.length > 0) {
        NormalNewsTypeId = 3;
        this.selectedVideoGallery.forEach((galleryFile) => {
          model.push({
            fileId: galleryFile,
            entityId: 10,
          });
        });
      }
      let requestModel = {
        agencyNewsId: 0,
        newsTypeEntityId: NormalNewsTypeId,
        newsFileList: [],
        newsPositionEntityIdList: positionIds,
      };
      requestModel.agencyNewsId = this.selectedNews.id;
      requestModel.code = this.selectedNews.code;
      this.selectedNews.agencyNewsFileList.forEach((file) => {
        let entityIds = model
          .filter((p) => p.fileId == file.id)
          .map((p) => p.entityId);
        if (entityIds.length > 0) {
          requestModel.newsFileList.push({
            agencyNewsFileId: file.id,
            newsFileTypeEntityIdList: entityIds,
          });
        }
      });
      http
        .post(`AgencyNews/copynewsfromagencynews`, requestModel)
        .then((response) => {
          if (response.success) {
            toastHelper.success(this.$t(response.message));
            // this.$emit("onSaved");
            this.$router.push("/news-add/" + response.data);
          } else {
            toastHelper.error(this.$t(response.message));
          }
        });
    },
    setColumns() {
      this.fileTypeEntities.forEach((element) => {
        if (element.id != 10) {
          this.columns.push(element.entityName);
          this.radioColumns.push(element.entityName);
        } else {
          this.columnsVideo.push(element.entityName);
        }
      });
      this.radioColumns.forEach((item) => {
        this.selectedData[item] = 0;
      });
      this.options = {
        ...gridOptions.getAll(),
        debounce: 100,
        texts: {
          count: "",
        },
        perPageValues: [10, 25, 50, 100],
        headings: {
          thumbnail: this.$t("thumbnail"),
        },
        sortable: "",
        filterable: false,
      };
    },
  },
};
</script>