var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated"},[_c('div',{staticClass:"animated"},[(_vm.selectFileModal)?_c('NewsAgenciesFileType',{attrs:{"selectedNews":_vm.selectedNews,"fileTypeEntities":_vm.fileTypeEntities},on:{"onGoBack":() => (_vm.selectFileModal = false),"onSaved":_vm.onSaved}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectFileModal),expression:"!selectFileModal"}],staticClass:"animated"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('i',{staticClass:"icon-menu mr-1"}),_vm._v("\n        "+_vm._s(_vm.$t("newsAgencies"))+"\n      ")]),_vm._v(" "),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('DateBetweenPicker',{attrs:{"from":_vm.filters.fromPublishedAt,"to":_vm.filters.toPublishedAt},on:{"change":_vm.dateChanged}})],1)],1)],1)],1),_vm._v(" "),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('i',{staticClass:"fa fa-filter mr-1"}),_vm._v("\n        "+_vm._s(_vm.$t("filter"))+"\n      ")]),_vm._v(" "),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[(_vm.$auth.hasAuth(_vm.$options, 'list'))?_c('v-server-table',{ref:"table",staticClass:"dataGrid datagridDetail gridDetail",attrs:{"columns":_vm.columns,"options":_vm.options,"id":"dataTable"},scopedSlots:_vm._u([{key:"agencyNewsFileList",fn:function(props){return [(props.row.agencyNewsFileList.length === 0)?_c('b-img',{staticClass:"mt-1 mb-1",attrs:{"src":"/img/noPic.png","fluid":"","height":"50","width":"50","thumbnail":"","id":_vm.getPopoverId(props.row.id)}}):(
                    props.row.agencyNewsFileList[0].fileType === 'image'
                  )?_c('b-img',{staticClass:"mt-1 mb-1",attrs:{"src":props.row.agencyNewsFileList[0].link,"fluid":"","height":"50","width":"50","thumbnail":"","id":_vm.getPopoverId(props.row.id)}}):(
                    props.row.agencyNewsFileList[0].fileType === 'video'
                  )?_c('video',{staticClass:"img-thumbnail img-fluid",attrs:{"preload":"auto","src":props.row.agencyNewsFileList[0].link,"height":"50","width":"50","id":_vm.getPopoverId(props.row.id)}}):_vm._e(),_vm._v(" "),(props.row.agencyNewsFileList.length > 0)?_c('b-popover',{attrs:{"target":_vm.getPopoverId(props.row.id),"triggers":"hover","placement":"right"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t(props.row.agencyNewsFileList[0].fileType)))]},proxy:true}],null,true)},[_vm._v(" "),(
                      props.row.agencyNewsFileList[0].fileType === 'image'
                    )?_c('b-img',{attrs:{"src":props.row.agencyNewsFileList[0].link,"thumbnail":"","fluid":"","height":"400","width":"400"}}):(
                      props.row.agencyNewsFileList[0].fileType === 'video'
                    )?_c('video',{staticClass:"img-thumbnail img-fluid",attrs:{"preload":"auto","controls":"","src":props.row.agencyNewsFileList[0].link,"height":"700","width":"700","id":_vm.getPopoverId(props.row.id)}}):_vm._e()],1):_vm._e()]}},{key:"title",fn:function(props){return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(props.row.title))])]}},{key:"category",fn:function(props){return [_c('div',{staticClass:"text-left"},[_vm._v("\n                  "+_vm._s(props.row.parentCategory)+" > "+_vm._s(props.row.category)+"\n                ")])]}},{key:"buttons",fn:function(props){return [_c('div',{staticClass:"action-buttons"},[(_vm.$auth.hasAuth(_vm.$options, 'selectNews'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-brand",attrs:{"variant":"primary icon","title":_vm.$t('save')}},[_c('i',{staticClass:"fa fa-plus-square",on:{"click":function($event){return _vm.selectNews(props.row)}}})]):_vm._e()],1)]}},{key:"child_row",fn:function(props){return _c('div',{staticClass:"text-justify p-4",domProps:{"innerHTML":_vm._s(props.row.description)}})}}],null,false,2509159863)}):_vm._e()],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }