<template>
  <div class="animated">
    <div class="animated">
      <NewsAgenciesFileType
        :selectedNews="selectedNews"
        :fileTypeEntities="fileTypeEntities"
        @onGoBack="() => (selectFileModal = false)"
        @onSaved="onSaved"
        v-if="selectFileModal"
      />
    </div>
    <div class="animated" v-show="!selectFileModal">
      <b-card no-body>
        <b-card-header>
          <i class="icon-menu mr-1"></i>
          {{ $t("newsAgencies") }}
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="6">
              <DateBetweenPicker
                :from="filters.fromPublishedAt"
                :to="filters.toPublishedAt"
                @change="dateChanged"
              ></DateBetweenPicker>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <b-card-header>
          <i class="fa fa-filter mr-1"></i>
          {{ $t("filter") }}
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="12">
              <v-server-table
                :columns="columns"
                ref="table"
                :options="options"
                id="dataTable"
                class="dataGrid datagridDetail gridDetail"
                v-if="$auth.hasAuth($options, 'list')"
              >
                <template slot="agencyNewsFileList" slot-scope="props">
                  <b-img
                    class="mt-1 mb-1"
                    v-if="props.row.agencyNewsFileList.length === 0"
                    src="/img/noPic.png"
                    fluid
                    height="50"
                    width="50"
                    thumbnail
                    :id="getPopoverId(props.row.id)"
                  />
                  <b-img
                    v-else-if="
                      props.row.agencyNewsFileList[0].fileType === 'image'
                    "
                    class="mt-1 mb-1"
                    :src="props.row.agencyNewsFileList[0].link"
                    fluid
                    height="50"
                    width="50"
                    thumbnail
                    :id="getPopoverId(props.row.id)"
                  />
                  <video
                    v-else-if="
                      props.row.agencyNewsFileList[0].fileType === 'video'
                    "
                    preload="auto"
                    class="img-thumbnail img-fluid"
                    :src="props.row.agencyNewsFileList[0].link"
                    height="50"
                    width="50"
                    :id="getPopoverId(props.row.id)"
                  />
                  <b-popover
                    v-if="props.row.agencyNewsFileList.length > 0"
                    :target="getPopoverId(props.row.id)"
                    triggers="hover"
                    placement="right"
                  >
                    <template #title>{{
                      $t(props.row.agencyNewsFileList[0].fileType)
                    }}</template>
                    <b-img
                      v-if="
                        props.row.agencyNewsFileList[0].fileType === 'image'
                      "
                      :src="props.row.agencyNewsFileList[0].link"
                      thumbnail
                      fluid
                      height="400"
                      width="400"
                    />
                    <video
                      v-else-if="
                        props.row.agencyNewsFileList[0].fileType === 'video'
                      "
                      preload="auto"
                      controls
                      class="img-thumbnail img-fluid"
                      :src="props.row.agencyNewsFileList[0].link"
                      height="700"
                      width="700"
                      :id="getPopoverId(props.row.id)"
                    />
                  </b-popover>
                </template>

                <template slot="title" slot-scope="props">
                  <div class="text-left">{{ props.row.title }}</div>
                </template>
                <template slot="category" slot-scope="props">
                  <div class="text-left">
                    {{ props.row.parentCategory }} > {{ props.row.category }}
                  </div>
                </template>
                <template slot="buttons" slot-scope="props">
                  <div class="action-buttons">
                    <b-button
                      variant="primary icon"
                      v-b-tooltip.hover
                      :title="$t('save')"
                      class="mr-1 btn-brand"
                      v-if="$auth.hasAuth($options, 'selectNews')"
                    >
                      <i
                        class="fa fa-plus-square"
                        @click="selectNews(props.row)"
                      ></i>
                    </b-button>
                  </div>
                </template>
                <div
                  slot="child_row"
                  slot-scope="props"
                  class="text-justify p-4"
                  v-html="props.row.description"
                ></div>
              </v-server-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils/screen-helper";
import CustomModal from "../../widgets/form/CustomModal";
import DateBetweenPicker from "../../widgets/form/DateBetweenPicker";
import NewsAgenciesFileType from "../../widgets/news-agencies/NewsAgenciesFileType";
import toastHelper from "../../shared/toast-helper";
export default {
  name: "NewsAgenciesList",
  components: {
    ServerTable,
    CustomModal,
    DateBetweenPicker,
    NewsAgenciesFileType,
  },
  created() {
    this.getFileTypeEntities();
  },
  methods: {
    getPopoverId(id) {
      return "popover" + id;
    },
    getFileTypeEntities() {
      http.get(`Entities/getnewsfiletypeentities`).then((response) => {
        this.fileTypeEntities = response;
      });
    },
    getList() {
      this.$refs.table.setPage(1);
    },
    onSaved() {
      this.selectFileModal = false;
    },
    getTime(val) {
      if (val.indexOf(".") >= 0) {
        return val.substr(0, val.indexOf("."));
      } else {
        return val;
      }
    },
    selectNews(news) {
      if (news.agencyNewsFileList.length == 0) {
        toastHelper.error(this.$t("noNewsImage"));
      } else {
        this.selectFileModal = true;
        this.selectedNews = news;
      }
    },
    dateChanged(dates) {
      this.filters.fromPublishedAt = dates?.from ?? null;
      this.filters.toPublishedAt = dates?.to ?? null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.filters.fromPublishedAt = dateHelper.addDay(null, -2);
      this.filters.toPublishedAt = dateHelper.addDay(null, 1);
    });
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      selectFileModal: false,
      selectedNews: {},
      fileTypeEntities: [],
      filters: {
        fromPublishedAt: dateHelper.addDay(null, -2),
        toPublishedAt: dateHelper.addDay(null, 1),
      },
      columns: [
        "agencyNewsFileList",
        "title",
        "category",
        "city",
        "publishDate",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        pagination: { chunk: 10 },
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "AgencyNews/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "publishDate";
            data.ascending = 0;
          }
          if (this.filters) {
            data.fromPublishedAt = this.filters.fromPublishedAt;
            data.toPublishedAt = this.filters.toPublishedAt;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              utility.scrollToTop();
            });
        },
        sortable: "title,category,city,publishDate",
        headings: {
          title: this.$t("title"),
          description: this.$t("description"),
          category: this.$t("category"),
          city: this.$t("city"),
          publishDate: this.$t("agencyPublishDate"),
          agencyNewsFileList: this.$t("thumbnail"),
          buttons: this.$t("operations"),
        },
        templates: {
          publishDate: (h, row) =>
            moment(row.publishDate).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>
<style scoped>
td {
  padding: 0px !important;
}
</style>